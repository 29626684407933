import React, { useState } from "react";
import axios from "axios";
import message from "../assets/images/footer/message.png";
import backgroundShape1 from "../assets/images/svg/background-shape1.svg";
import backgroundShape2 from "../assets/images/svg/background-shape2.svg";
import location from "../assets/images/footer/location.png";
import callInfo from "../assets/images/footer/call-info.png";
import { API } from "../config/api";

const Footer = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "", subject: "" });
  const [status, setStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(`${API}/contact/colab-form`, { formData });
      setStatus({ type: "success", message: "Message sent successfully!" });
      setFormData({ name: "", email: "", message: "", subject: "" });
    } catch (error) {
      setStatus({ type: "error", message: "Failed to send message. Please try again." });
    } finally {
      setIsSubmitting(false);
    }

    // Clear the status message after 5 seconds
    setTimeout(() => {
      setStatus("");
    }, 5000);
  };

  return (
    <footer className="footer-area text-center" id="contact">
      <div className="parallax-overlay"></div>
      <div className="background-shape1">
        <img src={backgroundShape1} alt="background-shape1 img" />
      </div>
      <div className="background-shape2">
        <img src={backgroundShape2} alt="background-shape2 img" />
      </div>
      <div className="container">
        <h5>Contact Us</h5>

        <div style={{ textAlign: "center", alignItems: "center", justifyContent: "center" }} className="row container mb-5">
          <form
            style={{ padding: 10, maxWidth: 500, textAlign: "left", color: "white" }}
            className="contact-form"
            onSubmit={handleSubmit}
          >
            <div className="col-12 col-md-12">
              <label className="col-form-label form_label">Name</label>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
                className="form-control custom-form-control"
              />
            </div>

            <div className="col-12 col-md-12">
              <label className="col-form-label form_label">Email Address</label>
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
                className="form-control custom-form-control"
              />
            </div>

            <div className="col-12 col-md-12">
              <label className="col-form-label form_label">Subject</label>
              <select
                className="form-control custom-form-control"
                value={formData.subject}
                onChange={handleChange}
                name="subject"
              >
                <option value="">---</option>
                <option value="Customer Inquiry">Customer Inquiry</option>
                <option value="Collaboration Inquiry (Food Truck, Musical Act, etc...)">
                  Collaboration Inquiry (Food Truck, Musical Act, etc...)
                </option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="col-12 col-md-12">
              <label className="col-form-label form_label">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                className="form-control custom-form-control"
              ></textarea>
            </div>

            <button className="btn btn-primary mt-2" style={{ width: "100%" }} type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Sending...
                </>
              ) : (
                "Send Message"
              )}
            </button>
          </form>
          {status && (
            <div
              className={`alert ${
                status.type === "success" ? "alert-success" : "alert-danger"
              } alert-dismissible fade show`}
              role="alert"
            >
              {status.message}
            </div>
          )}
        </div>

        {/* Footer details */}
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="location-info">
              <img src={location} alt="location-ic" className="img-fluid" />
              <p>1300 N Price Rd, Pampa, TX 79065</p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="location-info">
              <img src={callInfo} alt="call-info-ic" className="img-fluid" />
              <p>
                <a href="tel:+012345678899">(806) 848-0094</a>
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="location-info">
              <img src={message} alt="message-ic" className="img-fluid" />
              <p>
                <a href="mailto:contact@foholic.com">CONTACT@WEEKENDSPTX.COM</a>
              </p>
            </div>
          </div>
        </div>
        <div className="sub-footer text-center">
          <span>2024 &copy; WEEKENDS. All rights reserved.</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
